body {
  height: 100%;
}

.gradient-underline {
  background: linear-gradient(to right, #a867d5, #2f1193);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}

.orange {
  color: #eb5527;
}

.light-orange {
  color: #fff7f4;
}

.bg-orange {
  background-color: #eb5527;
}

.bg-light-orange {
  background-color: #fcf4f1;
}

.border-orange {
  border: 2px solid #eb5527;
}

.background-image {
  background: url("../../assets/images/bg-wave-orange.png");
  background-size: cover;
  left: 0;
  width: 100%;
  /* height: 200px; */
  z-index: 2;
}

#footer {
  background-color: #eb5527;
  bottom: 0;
}

/* #footer-light{
    background-color: #FFE3DA;
    margin-top: -10px;
    z-index: 1;

} */
.background-image-contact {
  background: url("../../assets/images/bg-wave-orange.png") no-repeat center
    bottom;
  background-size: cover;
  opacity: 50%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: -2;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.error-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 1rem;
  transform: translateY(-100%);
  animation: slide-down 0.5s forwards;
}

.error-message.active {
  background-color: #ffdfdf;
  color: #ff4242;
}

.error-message.generating {
  background-color: #f1dbff; /* Adjust as needed */
  color: #2b1958; /* Adjust as needed */
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.dot-animation {
  display: inline-block;
  animation: blink 2s infinite;
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.analysis-result .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.analysis-result .circle.filled {
  background-color: #ffa500;
  color: white;
}

@media (min-width: 768px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.choice {
  position: relative;
}

.social-media-message {
  padding-left: 8rem;
  padding-top: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 1000;
}

.social-media-message p {
  font-size: 1rem;
  padding: 1rem;
}

/* styles.css or your relevant CSS file */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
