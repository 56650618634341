/* fonts.css */

@font-face {
  font-family: 'Red Hat Display';
  src: url('../assets/fonts/RedHatDisplay-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display Regular';
  src: url('../assets/fonts/RedHatDisplay-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Plus Jakarta';
  src: url('../assets/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans Regular';
  src: url('../assets/fonts/DMSans_24pt-Regular.ttf') format('truetype');
}